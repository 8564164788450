.container {
  max-width: min(1253px, 100%);
  //width: calc(100% - 20px);
  padding: 0 20px;
  margin: 0 auto;
  @media (min-width: $screenMedium) {
    // max-width: 1253px;
    width: 100%;
    // margin: 0 auto;
  }
}

.flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-end {
  justify-content: flex-end;
}

.fd-col {
  flex-direction: column;
}

.col {
}
