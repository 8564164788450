.dark-box {
  background-color: $dark-blue;
  border-radius: 10px;
  color: $white;
}

#module-section {
  & .hdr {
    border-bottom: 2px solid $mid-grey2;
    margin: 10px 25px 20px;
    padding: 7px 25px;
    display: flex;
    flex-wrap: wrap;

    & div {
      cursor: pointer;
      margin-right: 25px;

      & span {
        padding: 7px 0px;
        color: $light-grey;

        strong {
          font-weight: 400;
        }
      }

      & span:hover {
        border-bottom: 1px solid $green-highlight;
        color: $white;
      }
    }

    & .active {
      & span {
        border-bottom: 1px solid $green-highlight;
        color: $white;
      }

      & span:hover {
        color: $white;
      }
    }
  }

  & .shapes {
    display: none;
    margin: 40px 20px;
    padding: 60px 20px;
    background-color: $dark2;
    border-radius: 10px;
    & .posts {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      border-radius: 10px;

      .metabar {
        .colume-left {
          & span:first-child {
            margin-right: 0 !important;
          }

          & span.text-white {
            margin-left: 5px;
            margin-right: 15px;
            font-weight: 300;
          }
        }
      }

      & .metabar {
        visibility: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 20px;
        margin-right: 20px;
        background-color: $dark2;
        font-size: 13px;
        padding: 5px 30px 5px 15px;
        border-radius: 0px 0px 5px 5px;

        & .colume-right {
          & img {
            border-radius: 3px;
            cursor: pointer;
            margin-top: 5px;
          }

          & img:hover {
            background-color: $dark-grey;
          }
        }

        & .colume-left {
          display: flex;
          flex-wrap: wrap;

          & span:first-child {
            margin-right: 0 !important;
          }

          & span.text-white {
            margin-left: 5px;
            margin-right: 15px;
            font-weight: 300;
          }
        }
      }
    }
  }

  & .ontologies {
    display: none;
    margin: 40px 20px;
    padding: 60px 20px;
    background-color: $dark2;
    border-radius: 10px;

    .bottom_sec_for_pagination {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      & p {
        font-size: 13px;
        margin-right: 30px;
      }

      & .pagination {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        span {
          padding: 6px 12px;
          background-color: $dark-grey;
          font-size: 10px;
          color: $white;
          cursor: pointer;
          margin-right: 1px;
        }

        span.left-arrow img {
          margin-top: 2px;
          height: 8px;
        }

        span.left-arrow {
          border-radius: 5px 0px 0px 5px;
        }

        span.right-arrow {
          border-radius: 0px 5px 5px 0px;
        }

        span.right-arrow img {
          margin-top: 2px;
          height: 8px;
        }

        span:hover {
          background-color: $dark-bg;
        }
      }
    }

    & .posts {
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      border-radius: 10px;

      .metabar {
        .colume-left {
          & span:first-child {
            margin-right: 0 !important;
          }

          & span.text-white {
            margin-left: 5px;
            margin-right: 15px;
            font-weight: 300;
          }
        }
      }

      //   .post {
      //     transition: width 2s, height 2s, transform 2s;
      //     margin-bottom: 20px;

      //     & .one {
      //       display: flex;
      //       align-items: center;
      //       justify-content: space-between;
      //       border-radius: 5px;
      //       height: 65px;
      //       margin-left: 20px;
      //       margin-right: 20px;
      //       background-color: $dark-bg;
      //       padding: 5px 40px 5px 5px;

      //       & .details {
      //         display: flex;

      //         & span {
      //           margin-right: 10px;

      //           & .heading {
      //             color: $white;
      //           }

      //           & p {
      //             color: $light-grey;
      //           }

      //           & img {
      //             margin-top: 5px;
      //             margin-left: -20px;
      //             border-radius: 100%;
      //           }
      //         }
      //       }

      //       & .rating {
      //         margin-left: 50px;
      //         display: flex;
      //         align-items: center;

      //         img {
      //           margin-right: 5px;
      //         }

      //         span {
      //           color: $white;
      //           font-weight: 300;
      //         }
      //       }
      //     }

      & .metabar {
        visibility: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 20px;
        margin-right: 20px;
        background-color: $dark2;
        font-size: 13px;
        padding: 5px 30px 5px 15px;
        border-radius: 0px 0px 5px 5px;

        & .colume-right {
          & img {
            border-radius: 3px;
            cursor: pointer;
            margin-top: 5px;
          }

          & img:hover {
            background-color: $dark-grey;
          }
        }

        & .colume-left {
          display: flex;
          flex-wrap: wrap;

          & span:first-child {
            margin-right: 0 !important;
          }

          & span.text-white {
            margin-left: 5px;
            margin-right: 15px;
            font-weight: 300;
          }
        }
      }
    }

    & .post:hover .metabar {
      transition: 0.3s;
      padding: 5px 25px 5px 15px;
      visibility: visible;
    }

    & .post:hover .one {
      transition: 0.5s;
      border-radius: 5px 5px 0px 0px;
    }
  }

  & .dependencies {
    display: none;
    margin: 40px 20px;
    padding: 60px 20px;
    background-color: $dark2;
    border-radius: 10px;

    .bottom_sec_for_pagination {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      & p {
        font-size: 13px;
        margin-right: 30px;
      }

      & .pagination {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        span {
          padding: 6px 12px;
          background-color: $dark-grey;
          font-size: 10px;
          color: $white;
          cursor: pointer;
          margin-right: 1px;
        }

        span.left-arrow img {
          margin-top: 2px;
          height: 8px;
        }

        span.left-arrow {
          border-radius: 5px 0px 0px 5px;
        }

        span.right-arrow {
          border-radius: 0px 5px 5px 0px;
        }

        span.right-arrow img {
          margin-top: 2px;
          height: 8px;
        }

        span:hover {
          background-color: $dark-bg;
        }
      }
    }

    & .posts {
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      border-radius: 10px;

      .post {
        transition: width 2s, height 2s, transform 2s;
        margin-bottom: 20px;

        cursor: pointer;

        & .one {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 5px;
          height: 65px;
          margin-left: 20px;
          margin-right: 20px;
          background-color: $dark-bg;
          padding: 5px 40px 5px 5px;

          & .details {
            display: flex;

            & span {
              margin-right: 10px;

              & .heading {
                color: $white;
              }

              & p {
                color: $light-grey;
              }

              & img {
                margin-top: 5px;
                margin-left: -20px;
                border-radius: 100%;
              }
            }
          }

          & .rating {
            margin-left: 50px;
            display: flex;
            align-items: center;

            img {
              margin-right: 5px;
            }

            span {
              color: $white;
              font-weight: 300;
            }
          }
        }

        & .metabar {
          visibility: hidden;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-left: 20px;
          margin-right: 20px;
          background-color: $dark2;
          font-size: 13px;
          padding: 5px 30px 5px 15px;
          border-radius: 0px 0px 5px 5px;

          & .colume-right {
            & img {
              border-radius: 3px;
              cursor: pointer;
              margin-top: 5px;
            }

            & img:hover {
              background-color: $dark-grey;
            }
          }

          & .colume-left {
            display: flex;
            flex-wrap: wrap;

            & span:first-child {
              margin-right: 0 !important;
            }

            & span.text-white {
              margin-left: 5px;
              margin-right: 15px;
              font-weight: 300;
            }
          }
        }
      }

      & .post:hover .metabar {
        transition: 0.3s;
        padding: 5px 25px 5px 15px;
        visibility: visible;
      }

      & .post:hover .one {
        transition: 0.5s;
        border-radius: 5px 5px 0px 0px;
      }
    }
  }

  padding: 0 0 20px 0;

  .component {
    margin: 40px 20px;
    padding: 60px 20px;
    background-color: $dark2;
    border-radius: 10px;
    padding: 20px;
  }
}

.posts-carousel {
  max-width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;

  & .arrow-left {
    width: 50px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $dark-grey;
    cursor: pointer;
  }

  & .arrow-right {
    width: 50px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $dark-grey;
    cursor: pointer;
  }

  & .arrow-left:hover {
    background-color: $dark2;
  }

  & .arrow-right:hover {
    background-color: $dark2;
  }

  & .main {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-x: scroll;

    .post {
      transition: width 2s, height 2s, transform 2s;

      & .one {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        height: 65px;
        margin-left: 20px;
        margin-right: 20px;
        background-color: $dark-bg;
        padding: 5px 30px 5px 5px;

        & .details {
          display: flex;
          padding: 0px 50px 0px 0px;

          & span {
            margin-right: 10px;

            & .heading {
              color: $white;
            }

            & p {
              color: $light-grey;
            }

            & img {
              margin-top: 5px;
              margin-left: -20px;
              border-radius: 100%;
            }
          }
        }

        & .rating {
          margin-left: 50px;
          display: flex;
          align-items: center;

          img {
            margin-right: 5px;
          }

          span {
            color: $white;
            font-weight: 300;
          }
        }
      }

      & .metabar {
        display: flex;
        visibility: hidden;
        align-items: center;
        justify-content: space-between;
        margin-left: 20px;
        margin-right: 20px;
        background-color: $dark2;
        font-size: 13px;
        padding: 5px 30px 5px 15px;
        border-radius: 0px 0px 5px 5px;

        & .colume-right {
          & img {
            border-radius: 3px;
            cursor: pointer;
            margin-top: 5px;
          }

          & img:hover {
            background-color: $dark-grey;
          }
        }

        & .colume-left {
          display: flex;
          flex-wrap: wrap;

          & span:first-child {
            margin-right: 0 !important;
          }

          & span.text-white {
            margin-left: 5px;
            margin-right: 15px;
            font-weight: 300;
          }
        }
      }
    }

    & .post:hover .metabar {
      transition: 0.3s;
      padding: 5px 20px 5px 15px;
      visibility: visible;
    }

    & .post:hover .one {
      transition: 0.5s;
      border-radius: 5px 5px 0px 0px;
    }
  }
}

.hidden {
  display: none;
}

.sharebox.ontology-module {
  .social-icon:first-child {
    border-radius: 5px 0px 0px 5px;
  }
}

.post-meta-details {
  margin-top: 20px;
  margin-bottom: 20px;
}

.contributors {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;

  p {
    margin-bottom: 10px;
  }

  & img {
    margin-right: 5px;
  }

  & .more {
    margin-left: 10px;
    margin-top: 10px;
    font-size: 13px;
  }
}

.post-info-clap {
  display: flex;
  align-items: center;
  margin-top: 30px;

  & span {
    margin-left: 10px;
  }
}

.commentbox {
  background-color: $dark2;
  padding: 10px;
  margin-top: 20px;
  align-items: flex-end;
  border-radius: 10px;
  display: flex;
  color: $mid-grey;

  & .w-75 {
    width: 75%;
  }

  & .w-25 {
    width: 25%;

    & button {
      border: 0;
      background-color: $dark-grey;
      color: $white;
      float: right;
      border-radius: 8px;
      font-weight: 500;
      font-size: 15px;
    }

    & button:hover {
      background-color: $blue-light-bg;
      transition: 0.3s;
    }
  }
}

.comments {
  margin-top: 50px;

  & .no-comments-heading {
    font-size: 18px;
    color: $light-grey;
  }
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}
.subheading {
  font-size: 18px;
  color: $light-grey;
}

.module-version {
  margin: 20px 30px 0 0;
  text-align: center;

  p {
    font-size: 16px;
  }
}

.tag-sec {
  margin-top: 50px;

  .subheading {
    margin:25px 0px 15px;
  }
  .tags {
    display: flex;
    flex-wrap: wrap;
    margin: 0px;
    user-select: none;
    button,a {
      margin-right: 5px;
      margin-bottom: 5px;
    }
    & .active {
      color: $white;
      background-color: $blue-lighter;
    }
  }
}

.avatar-circle {
  border-radius: 50%;
}

.action_bar.module {
  flex-wrap: wrap;
  align-items: center;

  & .js-btn {
    padding: 10px 20px;
    border-radius: 2px;
  }

  & .result-btn {
    padding: 10px 20px;
    margin-left: 2px;
  }

  & .result-btn:hover {
    background-color: $light-mid-grey;
  }
}

.cover {
  padding: 0;

  & img {
    width: 100%;
  }
}

.post-container {
  display: flex;
  flex-wrap: wrap;
  background-color: $dark-grey;
  padding: 20px;

  & .post-content-area {
    width: 70%;
  }

  & .post-right-sidebar {
    width: 30%;
    padding-left: 50px;
    // padding-right: 50px;
  }
}

.post-sponsor {
  & p {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  button {
    border-radius: 10px;
    border: 0px;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: $dark2;
    color: $light-grey;
    font-size: 13px;
    font-weight: 550;

    & img {
      margin-top: 5px;
      margin-right: 10px;
    }
  }

  button:hover {
    background-color: $black;
    color: $white;
  }
}

.mt-5px {
  margin-top: 5px;
}
