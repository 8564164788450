@import './scss/_variables.scss';

.spinner.spinner--active {
  border: 4px solid #474545;
  border-top: 4px solid $blue-light-bg;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  &.margin {
    margin: 1rem;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
