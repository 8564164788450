.dark-box {
  background-color: $dark-blue;
  border-radius: 10px;
  color: $white;
}

.recommended-components {
}

.post-container .post-content-area {
  width: 100% !important;
}

.posts-carousel {
  max-width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;

  & .arrow-left {
    width: 50px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $dark-grey;
    cursor: pointer;
  }

  & .arrow-right {
    width: 50px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $dark-grey;
    cursor: pointer;
  }

  & .arrow-left:hover {
    background-color: $dark2;
  }

  & .arrow-right:hover {
    background-color: $dark2;
  }

  & .main {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-x: scroll;

    .post {
      transition: width 2s, height 2s, transform 2s;

      & .one {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        height: 65px;
        margin-left: 20px;
        margin-right: 20px;
        background-color: $dark-bg;
        padding: 5px 30px 5px 5px;

        & .details {
          display: flex;
          padding: 0px 50px 0px 0px;

          & span {
            margin-right: 10px;

            & .heading {
              color: $white;
            }

            & p {
              color: $light-grey;
            }

            & img {
              margin-top: 5px;
              margin-left: -20px;
              border-radius: 100%;
            }
          }
        }

        & .rating {
          margin-left: 50px;
          display: flex;
          align-items: center;

          img {
            margin-right: 5px;
          }

          span {
            color: $white;
          }
        }
      }

      & .metabar {
        display: flex;
        visibility: hidden;
        align-items: center;
        justify-content: space-between;
        margin-left: 20px;
        margin-right: 20px;
        background-color: $dark2;
        font-size: 13px;
        padding: 5px 30px 5px 15px;
        border-radius: 0px 0px 5px 5px;

        & .colume-right {
          & img {
            margin-top: 5px;
          }
        }

        & .colume-left {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }

    & .post:hover .metabar {
      transition: 0.3s;
      padding: 5px 20px 5px 15px;
      visibility: visible;
    }

    & .post:hover .one {
      transition: 0.5s;
      border-radius: 5px 5px 0px 0px;
    }
  }
}

// ontology-module, since they aren't popups,
// whereas component is, so they'll behave differently
.sharebox.ontology-module {
  .social-icon:first-child {
    border-radius: 5px 0px 0px 5px;
  }
}

pre {
  overflow: scroll;
  max-height: 60vh;
}

.action_bar.ontology {
  flex-wrap: wrap;
  align-items: center;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);

  & .js-btn {
    padding: 10px 20px;
    border-radius: 1px;
    margin-right: 1px;
  }

  & .js-btn:first-child {
    border-radius: 2px 0px 0px 2px;
  }

  & .result-btn {
    padding: 10px 20px;
    margin-left: 1px;
    border-radius: 2px;
  }

  & .result-btn:hover {
    background-color: $light-mid-grey;
  }
}
