.overflow-none {
  overflow: hidden;
}

.codeWrapper {
  margin-bottom: 20px;
}

.blur {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.popup-wrapper {
  position: absolute;
  top: 5vh;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.bg-blur {
  position: fixed;
  backdrop-filter: blur(1em);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000000;
}

.popup {
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 10000001;
  transition: left 0.3s ease;
  top: 0;

  & .popup-container {
    display: flex;
    align-items: center;
    max-width: min(1000px, 100%);
    margin: 0 auto;

    & .popup-arrow {
      width: 10%;
      cursor: pointer;

      & .unzoom-btn {
        cursor: pointer;
        position: relative;
        top: -30vh;
        left: 10px;
      }

      & .popup-arrow-container {
        background-color: $dark-grey;
        border-radius: 5px;

        & .popup-arrow {
          padding: 40px 0px;
          filter: blur(20);
          @media (max-width: $screenTablet) {
            img {
              margin-left: -10px;
            }
          }
        }
      }

      & .popup-arrow-container:hover {
        background-color: $dark-bg;
      }
      @media (max-width: $screenTablet) {
        width: 5%;
      }
    }

    .popup-middle {
      width: 80%;
      @media (max-width: $screenTablet) {
        width: 90%;
      }

      & .popup-middle-container {
        border-radius: 10px;
        background-color: $dark-grey;
        height: auto;
        max-width: 800px;
        // height: 100vh;
        overflow-y: auto;
        margin: 50px auto;

        & .license {
          padding: 0 20px;

          & .license-container {
            background-color: $dark2;
            height: 120px;
            overflow-y: auto;
            padding: 20px;
            margin-bottom: 30px;

            & p {
              color: white;
              font-size: small;
            }
          }
        }

        .popup-middle-hdr {
          display: flex;
          justify-content: space-between;
          align-items: center;

          & .right img {
            margin-right: 20px;
          }

          & .right .view-in-editor {
            border: 0;
            background-color: inherit;
            color: $white;
            margin-right: 10px;
            font-size: small;
            margin-right: 20px;
            border-radius: 3px;
            font-size: 14px;
          }

          & .right .view-in-editor:hover {
            background-color: $blue-light-bg;
            transition: 0.3s;
          }

          & .post-title {
            color: $white;
            margin-bottom: 2px;
          }

          & .post-author {
            margin-right: 10px;
          }

          & .post-author-avatar {
            margin-right: 10px;
          }

          & .author-details {
            display: flex;
            padding: 10px;
          }
        }
      }
    }
  }
}

.popup.active-aside {
  //left: -110px;
}

.sharebox.component {
  & button {
    height: 30px;
    border-radius: 3px;

    & .copylink {
      text-shadow: 2px 2px $black;
    }
  }

  & .social-icon {
    border-radius: 3px;
  }
}

.commentbox.component {
  & .w-75 {
    & p {
      font-size: 13px;
    }
  }
}

.divider {
  padding: 5px;
}

.divider.ontology-module {
  margin-bottom: 30px;
}
.description {
  padding: 0rem 1.8rem;
}

.license-hdr {
  background-color: $dark-grey;
  padding: 20px;

  & p {
    color: white;
    font-size: 18px;
  }

  & .copytext {
    color: $mid-grey;
    margin-left: 5px;
  }
}

.action_bar.component {
  & .js-btn {
    padding: 10px 15px;
  }

  & .result-btn {
    padding: 10px 15px;
  }

  & .result-btn:hover {
    transition: 0.3s;
    border-color: $blue-light-bg;
    background-color: $blue-light-bg;
  }
}

.cover-component {
  padding: 10px;
  background-color: $black;

  & img {
    width: 100%;
  }
}

.post-container-component {
  display: flex;
  flex-wrap: wrap;
  background-color: $dark-grey;
  padding: 20px;
  border-radius: 10px;

  & .post-content-area {
    width: 100%;
  }

  & .post-right-sidebar {
    width: 30%;
    margin-left: 50px;
  }
}

.cursor {
  cursor: pointer;
}

.text-sm {
  font-size: 13px;
}

.text-md {
  font-size: 16px;
}
